import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';

import { Observable } from 'rxjs/internal/Observable';
import { take, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import { of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<fromRoot.State>, private router: Router) {
        // console.log('in auth guard');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        //  console.log('AuthGuard: Checking route:', state.url);
        //  return of(true); // Wrap the boolean in an Observable
        localStorage.setItem('redirectAfterLogin', state.url);
        return this.store.select(fromRoot.getIsAuth).pipe(
            take(1),
            tap((isAuthenticated) => {
                if (!isAuthenticated) {
                    // Store the attempted URL for redirecting after login
                    // console.log('AuthGuard: redirectAfterLogin:', state.url);

                    this.router.navigate(['sessions/signin']);
                }
            })
        );
    }

    canLoad(route: Route) {
        return this.store.select(fromRoot.getIsAuth).pipe(take(1));
    }
}
